<template>
  <window-content v-if="!loading">
    <div class="wrapper-sm">
      <e-row class="m-b-sm">
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="90px"
          >
            <erp-input simple-border v-model="remetente.id" readonly/>
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Canal:"
              label-width="90px"
          >
            <erp-select size="2" v-model="remetente.canal" :options="[
                {label: 'E-mail', value: 'email'},
                {label: 'Whatsapp', value: 'whatsapp'},
                {label: 'SMS', value: 'sms'},
                {label: 'Voz', value: 'voz'},
            ]" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="90px"
          >
            <erp-input size="2" autofocus simple-border v-model="remetente.nome"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Remetente:"
              label-width="90px"
              style="display: flex;"
          >
            <erp-input size="2" simple-border v-model="remetente.remetente"/>
          </erp-s-field>
          <!--<u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="adicionaTipo" />-->
        </e-col>
      </e-row>

      <e-row class="m-b-sm m-t">
        <e-col>
          <erp-s-field
              label="Definições:"
              label-width="90px"
          >
            <code-editor v-model="remetente.config" :languages="[['javascript']]" :display_language="false" class="source-code m-t-sm" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              label="Enviar um teste:"
              icon-help="Lembre-se de salvar primeiro para enviar um teste"
          >
            <erp-input size="2" v-model="teste" class="sl-input_" />
            <div class="m-t-sm">
              <e-btn :disable="!teste" label="Enviar" @click="test" />
            </div>
            <div class="m-t" v-if="testando">
              <sl-loading content="" class="size-18" />
            </div>
            <div v-if="testeOutput" class="m-t" :class="{'text-positive': testeSucesso === true, 'text-negative': testeSucesso === false}">
              {{testeOutput}}
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>

    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save"/>
      </div>
    </window-footer>
  </window-content>
  <window-loading :errors="errors" :status="status" v-else/>
</template>

<script>
import {ErpCheckbox, ErpInput, ErpSelect, ErpSField, WindowContent, WindowFooter, EBtn} from 'uloc-vue-plugin-erp'
import ERow from "../../../../layout/components/Row"
import ECol from "../../../../layout/components/Col"
import {
  find,
  newRemetente,
  updateRemetente,
  testRemetente
} from "@/domain/marketing/services/remetente"
import WindowSuccess from "../../../../layout/window/Success"
import TimerCloseWindow from "../../../../layout/window/TimerClose"
import WindowLoading from "../../../../layout/window/Loading"
import CodeEditor from 'simple-code-editor'
import SlLoading from "components/layout/components/Loading.vue";

export default {
  name: 'RemetenteWindow',
  props: ['id', 'props'],
  data() {
    return {
      loading: this.props && this.props.id ? !!this.props.id : !!this.id,
      errors: null,
      success: false,
      status: null,
      teste: null,
      testando: false,
      testeSucesso: null,
      testeOutput: null,
      remetente: {
        canal: 'email',
        nome: null,
        remetente: null,
        config: '',
        active: true
      }
    }
  },
  computed: {
    getId() {
      return this.props && this.props.id ? this.props.id : this.id
    }
  },
  mounted() {
    this.getId && this.load()
  },
  methods: {
    load() {
      this.loading = true
      find(this.getId)
          .then(response => {
            const data = response.data
            this.remetente = data
          })
          .catch(error => {
            console.log(error)
            this.errors = error
          })
          .finally(() => {
            this.loading = false
          })
    },
    test() {
      this.testando = true
      this.testeOutput = null
      this.testeSucesso = null
      testRemetente(this.getId, {
        destinatario: this.teste
      })
          .then(response => {
            this.testeSucesso = true
            this.testeOutput = response.data
          })
          .catch(error => {
            this.testeSucesso = false
            this.testeOutput = error.data
          })
          .finally(() => {
            this.testando = false
          })
    },
    save() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.remetente))
      data.config = JSON.stringify(data.config)
      const method = this.getId ? updateRemetente(data.id, data) : newRemetente(data)
      method
          .then(() => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.loading = false
                this.success = true
                this.$uloc.window.emit(this.$root.wid, 'update', this.getId)
                this.dg()
                // this.$uloc.window.close(this.$root.wid)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            // this.errors = error.data
            this.alertApiError(error)
            console.error(error, error.data)
            this.loading = false
            this.success = null
          })
    }
  },
  components: {
    SlLoading,
    EBtn,
    ErpSelect,
    WindowLoading,
    ECol,
    ERow,
    ErpSField,
    ErpInput,
    // ErpTabs,
    // ErpTabItem,
    WindowContent,
    // WindowBody,
    WindowFooter,
    CodeEditor
    // ErpCheckbox
  }
}
</script>
